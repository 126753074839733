import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import moment from "moment-timezone";

export default function MarketClose({ timeZone }) {
  const [timeDifference, setTimeDifference] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment().tz(timeZone);
      const dayOfWeek = now.day(); // Sunday = 0, Monday = 1, ..., Saturday = 6
      const hourOfDay = now.hour();

      let nextOpenTime;

      // If it's between Monday and Saturday
      if (dayOfWeek >= 1 && dayOfWeek <= 6) {
        if (hourOfDay >= 1 && hourOfDay < 2) {
          // Closed from 1 AM to 2 AM
          nextOpenTime = now.clone().startOf("hour").add(1, "hours");
        } else if (dayOfWeek === 6 && hourOfDay >= 1) {
          // Week close: Saturday 1 AM, open at Monday 2 AM
          nextOpenTime = now
            .clone()
            .startOf("week")
            .add(1, "weeks")
            .day(1)
            .hour(2);
        } else {
          // Open time other than the above conditions
          nextOpenTime = null;
        }
      } else {
        // It's Sunday, opens at Monday 2 AM
        nextOpenTime = now.clone().startOf("week").add(1, "days").hour(2);
      }
      if (nextOpenTime) {
        const difference = nextOpenTime.diff(now);
        setTimeDifference({
          days: Math.floor(moment.duration(difference).asDays()),
          hours: Math.floor(moment.duration(difference).asHours()) % 24,
          minutes: moment.duration(difference).minutes(),
          seconds: moment.duration(difference).seconds(),
        });
      } else {
        setTimeDifference({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timeZone]);

  const formattedTimeDifference = `${
    timeDifference.days === 0 ? "" : `${timeDifference.days} days`
  } ${timeDifference.hours === 0 ? "" : `${timeDifference.hours} hours`} ${
    timeDifference.minutes === 0 ? "" : `${timeDifference.minutes} minutes`
  }  ${timeDifference.seconds} seconds`;

  return (
    <Box
      sx={{
        position: "fixed",
        top: "0",
        left: "-125px",
        right: "0",
        bottom: "0",
        width: "895px",
        height: "531px",
        overflow: "hidden",
        zIndex: 10000,
      }}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "10%",
          transform: "rotate(-35deg)",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#FFFFFF",
          color: "#362F22",
          fontSize: "2vw",
          fontWeight: "bold",
          padding: "1vw",
        }}
      >
        <marquee scrollamount="5">
          {timeDifference && (
            <p style={{ marginLeft: "90px" }}>
              Market closed! Opens on {formattedTimeDifference}
            </p>
          )}
        </marquee>
      </div>
    </Box>
  );
}
